/* GOOGLE INTER FONT */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('./font/Satoshi-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./font/Satoshi-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./font/Satoshi-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./font/Satoshi-Regular.otf') format('opentype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(44, 50, 85, 1);
}

/* ------------ CELL PHONE CSS -------------- */
.cellphone-frame {
  display: flex;
  max-width: 562px;
  justify-content: center;
  align-items: center;
}

.cellphone-frame > .left {
  margin-right: -315px;
  animation: slideLeftCellAnimation 1.5s cubic-bezier(0.4, 0, 0.6, 0.6) forwards;
  animation-delay: 0.5s;
}
.cellphone-frame > .center {
  position: relative;
}
.cellphone-frame > .right {
  margin-left: -315px;
  animation: slideRighCelltAnimation 1.5s cubic-bezier(0.4, 0, 0.6, 0.6)
    forwards;
  animation-delay: 0.5s;
}

@keyframes slideLeftCellAnimation {
  0% {
    margin-right: -315px;
  }
  100% {
    margin-right: -155px;
  }
}
@keyframes slideRighCelltAnimation {
  0% {
    margin-left: -315px;
  }
  100% {
    margin-left: -155px;
  }
}

@media (max-width: 768px) {
  /* Adjust sizes for small screens */
  .cellphone-frame > img {
    width: 220px;
  }
}

@media (max-width: 320px) {
  /* Adjust sizes for small screens */
  .cellphone-frame > img {
    width: 120px;
  }

  .cellphone-frame > .left {
    margin-right: -115px;
  }
  .cellphone-frame > .right {
    margin-left: -115px;
  }

  @keyframes slideLeftCellAnimation {
    0% {
      margin-right: -115px;
    }
    100% {
      margin-right: -55px;
    }
  }
  @keyframes slideRighCelltAnimation {
    0% {
      margin-left: -115px;
    }
    100% {
      margin-left: -55px;
    }
  }
}

/* ------------ CELL PHONE CSS END -------------- */
